<template>
  <div
    id="featured-container"
    class="featured-container"
    v-bind:style="{ 'background-image': 'url(' + backgroundImage + ')' }"
    v-in-viewport
  >
    <div class="gradient"></div>
    <div class="subtitle"></div>
    <div class="title"><img src="/img/Phoenix.svg" alt="White text that reads Phoenix" /></div>
    <div class="featured-mobile-image-wrapper">
      <lobster-image aspect-ratio="16-9" :image-alt="imageAlt" :url="backgroundImage"></lobster-image>
    </div>
    <div class="highlights" id="highlights">
      <div class="highlight body-larger">
        <div class="icon"><img src="/img/restaurants.svg" alt="Fork and Map Pin" /></div>
        <div>240 <br />Restaurants</div>
      </div>
      <div class="highlight body-larger">
        <div class="icon"><img src="/img/vacationhotels.svg" alt="Six Pedal Flower Icon" /></div>
        <div class="">95 Vacation <br />Friendly Hotels</div>
      </div>
      <div class="highlight body-larger">
        <div class="icon"><img src="/img/rate.svg" alt="Price Tag" /></div>
        <div>Hotels From $104 or 20,000 Points per Night</div>
      </div>
    </div>
    <div
      class="slide-out inverse-color"
      id="slide-out"
      :class="{ in: true}"
      :style="slideStyles"
      v-in-viewport
    >
      <lobster-layout width="100" class="lobster_layout_padding-all--xl">
        <lobster-carousel
          navigation-position="below"
          gutter="small"
          slide-width="100"
          slide-width-xs="100"
          slide-width-sm="50"
          slide-width-md="33"
          slide-width-lg="33"
        >
          <template v-for="(info, index) in slides">
            <lobster-carousel-slide :key="index" slot="lobster-carousel-slide">
              <a :key="index" :href="info.url">
                <div class="slide-out-card">
                  <!-- <img class="slide-image" :src="info.image"> -->
                  <lobster-image
                    :url="info.image"
                    :image-alt="info.alt"
                    aspect-ratio="1-1"
                  ></lobster-image>
                  <div class="body-large">{{ info.title }}</div>
                </div>
              </a>
            </lobster-carousel-slide>
          </template>
        </lobster-carousel>
      </lobster-layout>
    </div>
  </div>
</template>

<script>
import featuredSlides from "@/data/featuredSlides";

export default {
  name: "featured-destination",
  data() {
    return {
      backgroundImage: "/img/phxcb-garden-0123-hor-clsc.jpg",
      imageAlt: "Pink and blue clouds of a setting sky behind flowery landscaping with tan buildings surrounding on all sides",
      offsetTop: 0,
      inView: false,
      slides: [],
      slideStyles: {}
    };
  },
  mounted() {
    this.slides = featuredSlides;
  },
  computed: {
    showCarousel() {
      return this.inView;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(e) {
      const el = document.querySelector('#featured-container.in-viewport');
      if (el) {
        const scrollPos = el.getBoundingClientRect().top
        const trans = scrollPos > 0 ? scrollPos : 0
        
        this.slideStyles = {
          transform: `translateX(${trans}px)`,
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../node_modules/@hlk/lobster/lobster-global-theme/_variables.scss";
@import "../../node_modules/@hlk/lobster/lobster-global-theme/_mixins.scss";

.featured-mobile-image-wrapper {
  min-height: 312px;
  @include gt-md {
    display: none;
  }
  div {
    min-height: 312px;
  }
}

.featured-container {
  width: 100%;
  height: 90vh;
  min-height: 800px;
  background-size: 100% 100%;
  position: relative;
  background-color: #000000;
  @include lt-md {
    min-height: 1000px;
  }

  @include lt-md {
    background-image: none !important;
  }

  @include gt-md {
    .lobster_carousel_controls--wrapper {
      display: none;
    }
  }

  .subtitle, .title, .highlights {
    z-index: 99;
    position: relative;
  }

  .title {
    width: 100%;
    text-align: center;
    color: white;
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));

    @include lt-md {
      position: absolute;
      left: 0;
      right: 0;
      top: 40px;
      z-index: 1;
    }
  }

  .title img {
    margin: 0 auto;
    max-width: 60%;
    @include lt-md {
      max-width: 75%;
    }
  }

  .subtitle {
    padding: 20px 0;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    color: white;
    @include lt-md {
      position: absolute;
      left: 0;
      right: 0;
      top: 40px;
      z-index: 1;
    }
  }

  .highlights {
    color: white;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 20px;

    @include lt-md {
      flex-direction: column;
    }
  }

  .highlight {
    padding: 0 20px;
    display: flex;
    max-width: 300px;
    flex-direction: row;
    //align-items: center;
    text-align: left;
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));

    @include gt-md {
      flex-direction: row;
      text-align: left;
    }

    @include lt-md{
      max-width: 100%;
      margin-top: 15px;
      br {
        display: none;
      }
    }
  }

  .highlight .icon {
    font-size: 2em;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include gt-md {
      margin-right: 5px;
    }
    
    @include lt-md{
      box-sizing: border-box;
      text-align: center;
      min-width: 75px;
      width: 75px;
    }
  }

  .slide-out {
    width: 100%;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0%;
    opacity: 0;
    transform: translateY(100%);
  }

  .slide-out.in {
    width: 100%;
    opacity: 1;
    z-index: 99;
  }

  .slide-out.in-viewport{
    width: 100%;
    opacity: 1;
    z-index: 99;
  }

  .slide-out > lobster-layout{
    padding-bottom: 80px;
  }

  .slide-out lobster-carousel {
    width: 850px;
    margin: 0 auto;
  }

  .slide-out a {
    text-decoration: none;
  }

  .slide-out-card {
    margin: 0 auto;
    text-decoration: none;
    color: #fff;
    lobster-image {
      border-radius: 20px;
      overflow: hidden;
      margin-bottom: 8px;
    }
  }

  .slide-out-card:hover{
    lobster-image div{
      transform: scaleX(1.1) ScaleY(1.1);
      transform-origin: center;
      transition: transform 0.25s linear;
    }
  }

  .slide-out-card {
    lobster-image div {
      transform: scaleX(1) ScaleY(1);
      transition: transform 0.25s linear;
    }
  }

  @media screen and (max-width: 960px) {
    height: auto;

    .slide-out.in{
      position: relative;
    }
  }

  @media screen and (max-width: 750px) {
    .slide-out.in {
      background-color: #000;
    }
  }
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, .8),
    rgba(0, 0, 0, 0.5) 15%,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0)
  );
}

</style>
