<template>
  <a class="tall-card" :href="info.url">
    <!-- <div class="tall-card"> -->
    <!-- <img class="background-image" :src="info.image" /> -->
    <lobster-image :url="info.image" :image-alt="info.alt" aspect-ratio="580-506"></lobster-image>
    <img class="logo" v-if="info.logo" :src="info.logo" />
    <div>
      <div v-if="info.eyebrow" class="eyebrow">{{ info.eyebrow }}</div>
      <div class="title headline-3">{{ info.title }}</div>
      <div class="chevron"><i aria-hidden="true" class="material-icons">chevron_right</i></div>
    </div>
    <!-- </div> -->
  </a>
</template>

<script>
export default {
  name: "tall-card",
  data() {
    return {};
  },
  props: {
    info: {},
  },
  methods: {},
};
</script>

<style lang="scss">
.tall-card {
  display: block;
  width: 100%;
  background-color: black;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  
  lobster-image div{
    transform: scaleX(1) ScaleY(1);
    transition: transform 0.25s linear;
  }
}

.tall-card:hover{
  lobster-image div{
    transform: scaleX(1.1) ScaleY(1.1);
    transform-origin: center;
    transition: transform 0.25s ease-in-out;
  }
}

.tall-card:after {
  content: "";
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 15px;
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0.5)),
    color-stop(100%, rgba(255, 255, 255, 0)),
    color-stop(100%, rgba(255, 255, 255, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* W3C */
}

.tall-card .background-image {
}

.tall-card > div {
  position: absolute;
  text-align: left;
  padding: 0 15px;
  width: 100%;
  z-index: 99;
  bottom: 0;
  color: #ffffff;
  padding: 35px;
}

.tall-card .title {
  left: 0;
  padding-top: 8px;
}

.tall-card .eyebrow {
}

.tall-card .logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 35px;
  max-width: 50%;
  margin: auto;
}

.chevron {
  position: absolute;
  right: 32px;
  bottom: 24px;
  text-align: left;
  font-size: 22px;
  z-index: 99;
}

@media screen and (max-width: 600px) {
  .tall-card-link {
  }

  .tall-card {
  }
}
</style>