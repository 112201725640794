<template>
  <div class="activities-search">
    <lobster-layout data-container="center" data-direction="row" data-wrap="true" data-justify="space-between">
        <lobster-layout width="100" width-md="63" width-lg="63">
          <typeahead
            label="Location"
            placeholder="City / Airport / Country"
            :getter="() => this.searchTerm"
            :setter="setSearchTerm"
            :data="results"
          ></typeahead>
        </lobster-layout>
        <lobster-layout width="100" width-md="31" width-lg="31">
          <a class="button submit custom_click_track" @click.prevent="go"
          custom_click_track_value="Search Widget - Tours Activities|Find a Hotel|external">
              Find an Activity
          </a>
        </lobster-layout>
    </lobster-layout>
  </div>
</template>

<script>
import * as R from 'ramda'
import axios from 'axios'
import FuzzySet from 'fuzzyset.js'
import typeahead from '@/components/search-parts/search-ui/typeahead'


export default {
    name: 'activities-search',
    props: {
      'setLoaded': Function
    },
    components: {
      typeahead,
    },
    mounted(){
    },
    data() {
      return {
        searchTerm: null,
        propertySearch: null,
        results: [],
        fromDate: null,
        toDate: null,
        locationData: null,
      }
    },
    methods: {
      setSearchTerm(term, preventReload = false) {
        const token = 'LPKGojlkZb--ebTzkOnW2i7SbDnsN2JV7U2yt2uNcVp7GL55n4ZSnn5h_3U67rIyHBN-_UItwkgE-v5Pvp-YMfDBIbmwLw4'
        
        this.searchTerm = term
        if (this.searchTerm && !preventReload) {
          axios({
            url: 'https://partner.pp-api.com/experiences/',
            method: 'post',
            headers: {
             Authorization: `Bearer ${token}`
           },
            data: {
              query: `query FilteredLocations($name: String!) {
                destinations(filter: { name: $name }, max: 5) {
                  results {
                    name url
                    destination {
                      name
                      type
                    }
                  }
                }
                pois(filter: { name: $name }, max: 5) {
                  results {
                    name
                    address
                    url
                  }
                }
              }`,
              variables: {
                name: this.searchTerm
              }
            }
          }).then((result) => {
            const res = R.path(['data', 'data', 'destinations', 'results'], result)
            
            this.locationData = res
            this.results = res ? R.map((item) => {
              const name = R.prop('name', item)
              const region = R.path(['destination', 0, 'name'], item)
              const country = R.path(['destination', 1, 'name'], item)
              let val = `${name}`
              if (region) {
                val = `${val}, ${region}`
              }
              if (country) {
                val = `${val}, ${country}`
              }

              return val
            }, res) : []
          });
        }
        else{
          this.results = [];
        }
      },
      setFromDate(date) {
        this.fromDate = date
      },
      setToDate(date) {
        this.toDate = date
      },
      go() {
        const loc = R.find(R.propEq('name', R.head(R.split(',', this.searchTerm))), this.locationData)
        let url = 'https://activities.marriott.com'
        
        if (loc) {
          url = loc.url
        }
        window.open(url, '_blank')
      },
    },
}
</script>

<style lang="scss">
@import '../../../node_modules/@hlk/lobster/lobster-global-theme/_variables.scss';
@import '../../../node_modules/@hlk/lobster/lobster-global-theme/_mixins.scss';

.activities-search > lobster-layout > lobster-layout {
  @include lt-md {
    margin-bottom: 20px;
  }
}

.activities-search lobster-datepicker {
  lobster-label {
    margin-left: 20px;
    position: relative;
    &:before {
      position: absolute;
      content:"";
      height: 100%;
      width: 20px;
      top: 0;
      left: -22px;
      background-image: url('/img/icons/calendar-icon-small.svg');
      background-position: center center;
    }
  }
}

.datepicker-to, .datepicker-from {
  width: 100%;
}

.datepicker-to {
  margin-top: 15px;
}

.submit.button{
    margin: 10px 0;
    background-color: black;
    color: white;
    height: 50px;
    border-radius: 25px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    font-family: Swiss721BT-Medium;
    font-size: 18px;
}

.mobile-form-container.active input{
  color: white;
}

.mobile-form-container.active .submit.button{
  background-color: white;
  color: black;
}
</style>
