<template>
  <div class="filterable-grid">
    <lobster-layout
      class="filterable-grid-container"
      data-container="centered"
      data-direction="row"
      data-wrap="true"
    >
      <lobster-layout
        width-lg="25"
        width-md="25"
        width-sm="100"
        width="100"
        v-if="content"
        data-direction="column"
      >
        <div class="filterable-grid-eyebrow eyebrow">{{ eyebrow }}</div>
        <h3 class="filterable-grid-title headline-1">{{ title }}</h3>
        <p class="filterable-grid-copy body-large">{{ copy }}</p>
      </lobster-layout>
      <lobster-layout
        class="grid-items-container"
        width-lg="75"
        width-md="75"
        width-sm="100"
        width="100"
      >
        <lobster-tabs v-if="content && !loading">
          <lobster-tabs-button
            v-for="(item, idx) in content"
            slot="lobster-tabs-button"
            :data.prop="item.name"
            :data-trigger="idx"
            :setter.prop="() => setCurrentTab(idx)"
          ></lobster-tabs-button>
          <lobster-tabs-item
            v-for="(item, idx) in content"
            slot="lobster-tabs-item"
            :data-trigger="idx"
            class="lobster_layout_padding-top--sm"
          >
            <!-- Grid Layout -->
            <lobster-carousel
              disabled-md="true"
              navigation-position="below"
              v-if="cardSets && cardSets.length"
              slide-width="100"
            >
              <lobster-carousel-slide
                v-for="set in cardSets"
                slot="lobster-carousel-slide"
              >
                <lobster-layout data-direction="row" data-wrap="true">
                  <lobster-layout
                    v-for="card in set"
                    width-lg="33"
                    width-md="33"
                    width-sm="100"
                    width="100"
                  >
                    <a :href="urlLookup(card.marsha_code)" class="grid-card" :style="gridCardStyle(card)">
                      <span role="img" :aria-label="altLookup(card.marsha_code)"></span>
                      <div class="grid-card-details">
                        <span class="grid-card-name">{{ card.name }}</span>
                        <span class="grid-card-location">{{ card.city }}</span>
                      </div>
                    </a>
                  </lobster-layout>
                </lobster-layout>
              </lobster-carousel-slide>
            </lobster-carousel>

            <!-- Carousel Layout -->
          </lobster-tabs-item>
        </lobster-tabs>
      </lobster-layout>
    </lobster-layout>
  </div>
</template>

<script>
import * as R from "ramda";
import { getPropertiesByCodeList, propertyData } from "@/utils";
import data from "@/data/filterableGrid.json";
import marshaUrl from "@/data/marshaUrlLookup.json";
import marshaPhoto from "@/data/marshaPhotoLookup.json";
import homes from "@/data/gridHomesAndVillas.json";

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export default {
  name: "filterable-grid",
  data() {
    return {
      loading: false,
      currentTab: 0,
      content: data,
      homesAndVillas: homes,
      urls: marshaUrl,
      photos: marshaPhoto
    };
  },
  props: {},
  mounted() {
  },
  methods: {
    setCurrentTab(data) {
      this.loading = true;
      this.currentTab = data;
      this.loading = false;
    },
    gridCardStyle(card) {
      return {
        backgroundImage: `url('${this.photoLookup(card.marsha_code)}')`,
        backgroundSize: "100% 100%",
        backgroundPosition: "center center",
        height: "220px",
      };
    },
    urlLookup(marsha){
      var url = this.urls[marsha];
      return url;
    },
    photoLookup(marsha){
      var photo = this.photos[marsha];
      if(photo)
        return photo.url;
      return "";
    },
    altLookup(marsha){
      var photo = this.photos[marsha];
      if(photo)
        return photo.alt;
      return "";
    }
  },
  computed: {
    title() {
      const l = R.lensIndex(this.currentTab);
      const d = R.view(l, this.content);
      return R.prop("title", d);
    },
    eyebrow() {
      const l = R.lensIndex(this.currentTab);
      const d = R.view(l, this.content);
      return R.prop("eyebrow", d);
    },
    copy() {
      const l = R.lensIndex(this.currentTab);
      const d = R.view(l, this.content);
      return R.prop("copy", d);
    },
    cards() {
      const l = R.lensIndex(this.currentTab);
      const d = R.view(l, this.content);

      if(this.currentTab === 3){
        return this.homesAndVillas;
      }

      if (this.currentTab === 0) {
        return getPropertiesByCodeList(
          R.take(9, shuffle(R.prop("cards", d))),
          propertyData
        );
      }
      return getPropertiesByCodeList(R.prop("cards", d), propertyData);
    },
    cardSets() {
      return this.cards ? R.splitEvery(3, this.cards) : null;
    },
  },
};
</script>

<style lang="scss">
@import "../../node_modules/@hlk/lobster/lobster-global-theme/_variables.scss";
@import "../../node_modules/@hlk/lobster/lobster-global-theme/_mixins.scss";

.filterable-grid {
  padding: 80px 0;

  @include lt-md {
    lobster-carousel-slide.lobster_layout_flex--100 {
      flex: 0 0 90% !important;
    }
    lobster-tabs-item {
      margin-right: -20px !important;
    }
  }
}

.filterable-grid-container {
  position: relative;
}

.grid-items-container {
  position: relative;
  max-width: 100%;
}

.filterable-grid-title {
  margin: 11px 13px 17px 0;
  color: #1c1c1c;
}

.filterable-grid-eyebrow {
  margin: 0 114px 11px 3px;
  color: #707070;
  @include gt-md {
    margin-top: 80px;
  }
}

.filterable-grid-copy {
  margin: 17px 49px 0px 1px;
  color: #1c1c1c;
}

.grid-card {
  min-width: calc(100% - 20px);
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  margin: 10px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.1);
  transition: background-size 0.25s linear;
}

.grid-card:hover{
  background-size: 110% 110% !important;
}

.grid-card-details {
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 17px 20px;
}

lobster-tabs {
  width: 100%;
}

.grid-card-name {
  font-family: Swiss721BT-Medium;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #1c1c1c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  + .grid-card-details {
    margin-top: 7px;
  }
}

.grid-card-location {
  font-family: Swiss721BT-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #707070;
}

.filterable-grid-layout {
  @include lt-md {
    display: none;
  }
}
</style>
