import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'

import { applyPolyfills, defineCustomElements } from '@hlk/lobster/dist/loader';
import '@/scss/main.scss';

Vue.config.productionTip = false;

// Tell Vue to ignore all components defined in the test-components
// package. The regex assumes all components names are prefixed
// 'test'
Vue.config.ignoredElements = [/lobster-\w*/, /pacsys-\w*/];

import inViewportDirective from 'vue-in-viewport-directive'
Vue.directive('in-viewport', inViewportDirective)

// Bind the custom elements to the window object
applyPolyfills().then(() => {
  defineCustomElements();
});

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
