<template>
  <div class="hero-content">
    <div class="eyebrow">{{ info.eyebrow }}</div>
    <h1 class="headline-1">{{ info.title }}</h1>
    <!-- <div class="headline-1">{{ info.title }}</div> -->
    <div class="body-large">{{ info.subcontent }}</div>
  </div>
</template>

<script>
export default {
  name: "hero-content",
  data() {
    return {};
  },
  props: {
    info: Object,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
@import "../../../node_modules/@hlk/lobster/lobster-global-theme/_variables.scss";
@import "../../../node_modules/@hlk/lobster/lobster-global-theme/_mixins.scss";

h1{
  margin-top: 0;
  margin-bottom:0;
  font-weight: inherit;
}

.hero-content {
  color: white;
  padding: 25px;

  @include lt-md {
    position: absolute;
    bottom: 174px;
    left: 0;
    width: 100%;
    padding: 10px 25px;
    transform: translateY(-100%);
  }
}

.hero-content .eyebrow {
  margin-bottom: 5px;
}

.hero-content .body-large {
  margin-top: 15px;
  width: 75%;
  color: #ffffff;

  @include lt-md {
    height: 0;
    margin: 0;
    display: none;
  }
}
</style>
