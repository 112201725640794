<template>
  <div class="section-header">
    <lobster-layout
      data-direction="column"
      data-justify="center"
      data-align="center"
      direction-md="row"
      justify-md="space-between"
    >
      <titleLine :title="title" v-if="title"></titleLine>
      <viewAll v-if="viewAllUrl" :url="viewAllUrl"></viewAll>
    </lobster-layout>
  </div>
</template>

<script>
import viewAll from "@/partials/view-all";
import titleLine from "@/partials/title-line";
export default {
  name: "section-header",
  data() {
    return {};
  },
  props: {
    title: String,
    viewAllUrl: String,
  },
  mounted() {},
  methods: {},
  components: {
    titleLine,
    viewAll,
  },
};
</script>

<style lang="scss">
.section-header {
  margin-bottom: 20px;

  > lobster-layout {
    position: relative;
  }

  .title-line{
    @media (min-width: 960px) {
      max-width: 75%;
    }
  }

  .title-line + .view-all {
    @media (max-width: 960px) {
      margin-top: 15px;
    }
    @media (min-width: 960px) {
      position: absolute;
      right: 72px;
    }
  }

  @media (min-width: 960px) {
    margin-bottom: 30px;
  }
}
</style>
