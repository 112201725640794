<template>
  <div class="vacations-packages-search">
    <div class="search-types">
      <lobster-layout data-container="center" data-direction="row" data-wrap="false" data-justify="flex-start">
        <a v-for="item in searchTypes" @click.prevent="setSearchType(item)" :class="{'active': item === activeSearchType }" href="#">{{item}}</a>
      </lobster-layout>
    </div>
    <lobster-layout data-container="center" data-direction="row" data-wrap="true" data-justify="space-between">
        <lobster-layout width="100" width-md="31" width-lg="31">
          <typeahead
            label="Location"
            placeholder="From City/Airport"
            :getter="() => this.searchTermFrom"
            :setter="setSearchTermFrom"
            :data="resultsFrom"
          ></typeahead>
        </lobster-layout>
        <lobster-layout width="100" width-md="31" width-lg="31">
          <typeahead
            label="Location"
            placeholder="To City/Airport"
            :getter="() => this.searchTermTo"
            :setter="setSearchTermTo"
            :data="resultsTo"
          ></typeahead>
        </lobster-layout>
        <lobster-layout width="100" width-md="31" width-lg="31">
          <a class="button submit desktop custom_click_track" @click.prevent="go"
          custom_click_track_value="Search Widget - VC Packages|Find a Hotel|external">
              Find a Vacation
          </a>
        </lobster-layout>
        <lobster-layout width="100" width-md="31" width-lg="31" data-direction="row" data-wrap="false" data-justify="space-between">
          <lobster-datepicker
            class="datepicker-from"
            data-label="Dates"
            data-placeholder="From"
            mode="single"
            :setter.prop="setFromDate"
          >
          </lobster-datepicker>
        </lobster-layout>
        <lobster-layout width="100" width-md="31" width-lg="31" data-direction="row" data-wrap="false" data-justify="space-between">
          <lobster-datepicker
            class="datepicker-to"
            data-label="Dates"
            data-placeholder="To"
            mode="single"
            :setter.prop="setToDate"
          >
          </lobster-datepicker>
        </lobster-layout>
        <lobster-layout width="100" width-md="31" width-lg="31" data-direction="row" data-wrap="false" data-justify="space-between">
          <a class="button submit mobile custom_click_track" @click.prevent="go"
          custom_click_track_value="Search Widget - VC Packages|Find a Hotel|external">
              Find a Vacation
          </a>
        </lobster-layout>
    </lobster-layout>
  </div>
</template>

<script>
import * as R from 'ramda'
import moment from 'moment'
import axios from 'axios'

import typeahead from '@/components/search-parts/search-ui/typeahead'

const serialize = function(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export default {
    name: 'vacations-packages-search',
    components: {
      typeahead,
    },
    mounted(){
    },
    data() {
      return {
        searchTermFrom: null,
        searchTermTo: null,
        fromDate: null,
        toDate: null,
        activeSearchType: 'Room + Flight',
        searchTypes: [
          'Room + Flight',
          'Room + Flight + Car',
          'Room + Car',
        ],
        resultsFrom: [],
        resultsTo: [],
        classOptions: [
          {
            "label": "Economy/Coach",
            "value": "COACH"
          },
          {
            "label": "Premium Economy",
            "value": "PREMIUM_ECONOMY"
          },
          {
            "label": "Business",
            "value": "BUSINESS"
          },
          {
            "label": "First Class",
            "value": "FIRST"
          },
        ],
        selectedClass: 'COACH',
      }
    },
    computed: {
    },
    methods: {
      setSearchTermFrom(term) {
        this.searchTermFrom = term
        if (this.searchTermFrom) {
          const params = {
            siteid: '10001',
            lob: 'PACKAGES',
            locale: 'en_US',
            maxresults: '5',
            features: 'uta_client:consistent_display:ta_hierarchy:nearby_airport',
            format: 'json',
            personalize: false,
            client: 'Vacations',
            guid: 'e92900aa703d4ee4910889004818424d',
          }
          axios.get(`https://vacations.marriott.com/api/v4/typeahead/${this.searchTermFrom}`, { params: params }).then((results) => {
            const res = R.path(['data', 'sr'], results)
            this.resultsFrom = res ? R.map(
              R.path(['regionNames', 'fullName']),
              res
            ) : []
          })
        }
        else {
          this.resultsFrom = [];
        }
      },
      setSearchTermTo(term) {
        this.searchTermTo = term
        if (this.searchTermTo) {
          const params = {
            siteid: '10001',
            lob: 'PACKAGES',
            locale: 'en_US',
            maxresults: '5',
            features: 'uta_client:consistent_display:ta_hierarchy:nearby_airport',
            format: 'json',
            personalize: false,
            client: 'Vacations',
            guid: 'e92900aa703d4ee4910889004818424d',
          }
          axios.get(`https://vacations.marriott.com/api/v4/typeahead/${this.searchTermTo}`, { params: params }).then((results) => {
            const res = R.path(['data', 'sr'], results)
            this.resultsTo = res ? R.map(
              R.path(['regionNames', 'fullName']),
              res
            ) : []
          })
        }
        else{
          this.resultsTo = [];
        }
      },
      setFromDate(date) {
        const dateObj = moment(date, "MM-DD-YYYY")
        this.fromDate = dateObj.format('YYYY-MM-DD')
      },
      setToDate(date) {
        const dateObj = moment(date, "MM-DD-YYYY")
        this.toDate = dateObj.format('YYYY-MM-DD')
      },
      setSearchType(t) {
        this.activeSearchType = t
      },
      setSelectedClass(data) {
        this.selectedClass = data
      },
      go() {
        // Handle search types
        let searchType = null
        if (this.activeSearchType === 'Room + Flight') {
          searchType = 'FlightHotel'
        }
        if (this.activeSearchType === 'Room + Flight + Car') {
          searchType = 'FlightHotelCar'
        }
        if (this.activeSearchType === 'Room + Car') {
          searchType = 'HotelCar'
        }

        // Construct Params
        const params = serialize({
          FromAirport: this.searchTermFrom,
          Destination: this.searchTermTo,
        })

        // Construct URL
        const url = `https://vacations.marriott.com/go/package/search/${searchType}/${this.fromDate}/${this.toDate}?${params}`
        window.open(url, '_blank');
      },
    },
}
</script>

<style lang="scss">
@import '../../../node_modules/@hlk/lobster/lobster-global-theme/_variables.scss';
@import '../../../node_modules/@hlk/lobster/lobster-global-theme/_mixins.scss';

.vacations-packages-search > lobster-layout > lobster-layout {
  @include lt-md {
    margin-bottom: 20px;
    &:nth-of-type(3) {
      margin-bottom: 0;
    }
  }

}

.vacations-packages-search lobster-datepicker {
  margin-top: 20px;
  &.datepicker-to {
    @include gt-md {
      // padding-top: 15px;
    }
  }
  lobster-label {
    margin-left: 20px;
    position: relative;
    &:before {
      position: absolute;
      content:"";
      height: 100%;
      width: 20px;
      top: 0;
      left: -22px;
      background-image: url('/img/icons/calendar-icon-small.svg');
      background-position: center center;
    }
  }
}

.vacations-packages-search lobster-select {
  margin-top: 22px;
  min-width: 100%;
}

.search-types {
  margin-bottom: 30px;
  margin-top: -10px;
  a {
    font-family: Swiss721BT-Medium;
    @include lt-md {
      color: #fff;
      font-family: Swiss721BT-Regular;
    }
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #5e5d5b;
    margin-right: 40px;
    text-decoration: none;
    padding-bottom: 10px;
    position: relative;

    &.active {
      color: #1c1c1c;
      @include lt-md {
        color: #fff;
        font-family: Swiss721BT-Medium;
      }
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        border-radius: 3px;
        background-color: #ff9662;
      }
    }
  }

  .vp-date {
    margin-top: 10px;
  }
}

.vacations-packages-search {
  @include gt-md {
    .submit.button.mobile {
      display: none;
    }
  }
  @include lt-md {
    .submit.button.desktop {
      display: none;
    }
  }
}
</style>
