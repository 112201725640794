<template>
  <div id="app">
    <header>
    <header-bar></header-bar>
    </header>
    <main>
    <hero  class='hero-animate' v-in-viewport></hero>
    <next-vacation  class='animate scale' v-in-viewport></next-vacation>
    <title-carousel :info="privateHomes"  class='animate scale' v-in-viewport></title-carousel>
    <pop-out  class='animate scale' v-in-viewport></pop-out>
    <featured-destination  class='animate' v-in-viewport></featured-destination>
    <side-by-side  class='animate scale' v-in-viewport></side-by-side>

    <filterable-grid  class='animate scale' v-in-viewport></filterable-grid>
    <travel-stories  class='animate scale' v-in-viewport></travel-stories>
    <title-carousel :info="cities"  class='animate scale' v-in-viewport></title-carousel>
    <pacsys-brand-ribbon
			class="component"
			brand-tier-one="Luxury"
      brand-tier-two="Premium"
      brand-tier-three="Select"
      brand-tier-four="Longer Stays">
    </pacsys-brand-ribbon>
    </main>
    <footer>
    <footer-component></footer-component>
    </footer>
  </div>
</template>

<script>
import headerBar from '@/components/header-bar'
import hero from '@/components/hero'
import nextVacation from '@/components/next-vacation'
import popOut from '@/components/pop-out'
import featuredDestination from '@/components/featured-destination'
import sideBySide from '@/components/side-by-side'
import titleCarousel from '@/components/title-carousel'
import filterableGrid from '@/components/filterable-grid'
import travelStories from '@/components/travel-stories'
import footerComponent from '@/components/footer'

// TMP

import privateHomes from '@/data/privateHomes'
import cities from '@/data/cities'

export default {
  name: 'App',
  data() {
    return {
      title: "", cities: {}, privateHomes: {}
    }
  },
  methods: {
    updateTitle() {
    },
  },
  components: {
    headerBar,
    hero,
    nextVacation,
    popOut,
    featuredDestination,
    sideBySide,
    titleCarousel,
    filterableGrid,
    travelStories,
    footerComponent,
  },
  mounted(){
    this.cities = cities;
    this.privateHomes = privateHomes;
  }
}
</script>

<style lang="scss">
@import "../node_modules/@hlk/lobster/lobster-global-theme/_variables.scss";
@import "../node_modules/@hlk/lobster/lobster-global-theme/_mixins.scss";

html, html.hydrated {
  width: 100vw;
  position: relative;
  overflow-x: hidden;
}

.scroll-lock {
  overflow: hidden;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}

body {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}
// Fonts
@font-face {
  font-family: 'Marriott-Brand-Icons';
  src:  url('./assets/fonts/portfolio-logo-icons/portfolio-logo-icons.eot?65xkc1');
  src:  url('./assets/fonts/portfolio-logo-icons/portfolio-logo-icons.eot?65xkc1#iefix') format('embedded-opentype'),
    url('./assets/fonts/portfolio-logo-icons/portfolio-logo-icons.ttf?65xkc1') format('truetype'),
    url('./assets/fonts/portfolio-logo-icons/portfolio-logo-icons.woff?65xkc1') format('woff'),
    url('./assets/fonts/portfolio-logo-icons/portfolio-logo-icons.svg?65xkc1#Marriott-Brand-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Swiss721BT-Regular';
  src:  url('./assets/fonts/Swiss721BT-Regular.eot');
  src:  url('./assets/fonts/Swiss721BT-Regular.eot') format('embedded-opentype'),
    url('./assets/fonts/Swiss721BT-Regular.ttf') format('truetype'),
    url('./assets/fonts/Swiss721BT-Regular.woff') format('woff'),
    url('./assets/fonts/Swiss721BT-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Swiss721BT-Medium';
  src:  url('./assets/fonts/Swiss721BT-Medium.eot');
  src:  url('./assets/fonts/Swiss721BT-Medium.eot') format('embedded-opentype'),
    url('./assets/fonts/Swiss721BT-Medium.ttf') format('truetype'),
    url('./assets/fonts/Swiss721BT-Medium.woff') format('woff'),
    url('./assets/fonts/Swiss721BT-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Proxima-Nova-Semi-Bold';
  src: url('./assets/fonts/ProximaNovaSemibold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Proxima-Nova-Regular';
  src: url('./assets/fonts/ProximaNovaRegular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'Proxima-Nova';
  src: url('./assets/fonts/ProximaNovaRegular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.animate {
  @include gt-md {
    opacity: 0.1;
    transition: all .55s ease-in;
    &.in-viewport {
      opacity: 1;
      transition: all .55s ease-in;
    }
  }
}
.scale {
  @include gt-md {
    opacity: 0.1;
    transform: scale(.99);
    transition: all .55s ease-in;
    &.in-viewport {
      opacity: 1;
      transition: all .55s ease-in;
      transform: scale(1);
    }
  }
}
</style>
