<template>
  <a :href="url" class="view-all"> View All&nbsp;&#8594; </a>
</template>

<script>
export default {
  name: "view-all",
  data() {
    return {};
  },
  props: {
    url: String,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.view-all {
  position: relative;
  text-decoration: none;
  color: inherit;
  font-size: 13px;
  font-family: Swiss721BT-Medium;
  color: #1c1c1c;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .view-all {
    width: 100%;
    display: block;
  }
}
</style>