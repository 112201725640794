<template>
    <div class="header-container">
        <lobster-layout data-direction="row" data-container="centered">
          <lobster-layout width="33">
            <div class="desktop-only">
              <svg class="portfolio__logo" viewBox="0 0 581.97 167.57" aria-labelledby="ribbon_title" role="img">
                <title id="ribbon_title">Marriott Bonvoy Logo</title>
                <g>
                  <rect fill="#ff9662" x="385.8" y="152.63" width="78.96" height="13.14"/>
                  <polygon class="neutral" points="537.29 75.85 511.18 113.08 485.07 75.85 467.14 75.85 503.32 127.03 503.32 165.78 519.04 165.78 519.04 127.03 519.09 126.97 555.22 75.85 537.29 75.85"/>
                  <path class="neutral" d="M64,119.09l-1.24-.94,1.07-1.09a23.42,23.42,0,0,0,6.72-16.17c0-13.81-12.72-25-28.34-25H0v89.92H44.58c16.53,0,30-11.88,30-26.49,0-7.78-3.86-15.15-10.6-20.19M15.71,89H42.17c7.36,0,13.34,5.34,13.34,11.9s-6,11.89-13.34,11.89H15.71Zm28.87,63.64H15.71V125.92H44.58c8.26,0,15,6,15,13.36s-6.72,13.35-15,13.35"/>
                  <polygon class="neutral" points="336.87 135.84 310.87 75.85 293.56 75.85 333.24 165.78 340.49 165.78 380.17 75.85 362.86 75.85 336.87 135.84"/>
                  <path class="neutral" d="M137.21,74.06A46.76,46.76,0,1,0,184,120.81,46.75,46.75,0,0,0,137.21,74.06Zm0,78.11a31.36,31.36,0,1,1,31.35-31.36A31.39,31.39,0,0,1,137.22,152.17Z"/>
                  <polygon class="neutral" points="261.56 133.83 210.52 75.85 202.69 75.85 202.69 165.78 218.38 165.78 218.38 107.8 269.42 165.78 277.25 165.78 277.25 75.85 261.56 75.85 261.56 133.83"/>
                  <path class="neutral" d="M425.28,74.06a31.36,31.36,0,1,0,31.36,31.36A31.4,31.4,0,0,0,425.28,74.06Zm0,49.43a18.08,18.08,0,1,1,18.07-18.07A18.1,18.1,0,0,1,425.28,123.49Z"/>
                  <polygon class="neutral" points="85.03 15.45 85.03 45.87 76.64 45.87 76.64 0.89 76.65 0.89 76.64 0.89 86.03 0.89 99.89 25.79 113.75 0.89 123.14 0.89 123.14 0.89 123.14 0.89 123.14 45.87 114.76 45.87 114.76 15.45 102.92 37.23 96.87 37.23 85.03 15.45"/>
                  <polygon class="neutral" points="443.65 0.89 443.65 8.64 456.92 8.64 456.92 45.87 465.3 45.87 465.3 8.64 478.57 8.64 478.57 0.89 443.65 0.89"/>
                  <polygon class="neutral" points="395.1 0.89 395.1 8.64 408.36 8.64 408.36 45.87 416.75 45.87 416.75 8.64 430.01 8.64 430.01 0.89 395.1 0.89"/>
                  <rect class="neutral" x="311.48" y="0.89" width="8.39" height="44.98"/>
                  <path class="neutral" d="M208.19,27.66H215L227.6,45.88h9.62l-12.7-18.41c7.19-1,12.7-6.54,12.7-13.2h0c0-7.38-6.78-13.39-15.11-13.39H199.8v45h8.39Zm0-19.66h13.92c3.9,0,7.08,2.81,7.08,6.27h0c0,3.46-3.18,6.28-7.08,6.28H208.19Z"/>
                  <path class="neutral" d="M162.07,15.89,168.61,30H155.52Zm-2-15-20.84,45h9l4-8.64H172l4,8.64h9L164.11.89Z"/>
                  <path class="neutral" d="M277.3.88H255v45h8.39V27.66h6.84l12.57,18.22h9.62l-12.7-18.41c7.19-1,12.7-6.54,12.7-13.2h0c0-7.38-6.78-13.39-15.11-13.39m7.08,13.39h0c0,3.46-3.17,6.27-7.08,6.27H263.38V8H277.3c3.91,0,7.08,2.81,7.08,6.27Z"/>
                  <path class="neutral" d="M360.15,0a23.38,23.38,0,1,0,23.38,23.38A23.38,23.38,0,0,0,360.15,0Zm0,38.38a15,15,0,1,1,15-15A15,15,0,0,1,360.15,38.38Z"/>
                  <path class="neutral" d="M564.81,77.07h-3.57V75.7h8.69v1.37h-3.57V87h-1.55Z"/>
                  <path class="neutral" d="M572.73,87h-1.41V75.7h2.2l2.81,8.67a6.88,6.88,0,0,1,.26.93,8.14,8.14,0,0,1,.26-.93l2.91-8.67H582V87h-1.5V77.37a7.67,7.67,0,0,1-.27,1L577.29,87h-1.45L573,78.36a7.16,7.16,0,0,1-.26-1Z"/>
                </g>
              </svg>
            </div>
          </lobster-layout>
          <lobster-layout width="33" data-direction="row" data-justify="center">
            <div class="mobile-only">
              <div class="svg-container">
                <svg class="portfolio__logo" viewBox="0 0 581.97 167.57" aria-labelledby="ribbon_title" role="img">
                  <title id="ribbon_title">Marriott Bonvoy Logo</title>
                  <g>
                    <rect fill="#ff9662" x="385.8" y="152.63" width="78.96" height="13.14"/>
                    <polygon class="neutral" points="537.29 75.85 511.18 113.08 485.07 75.85 467.14 75.85 503.32 127.03 503.32 165.78 519.04 165.78 519.04 127.03 519.09 126.97 555.22 75.85 537.29 75.85"/>
                    <path class="neutral" d="M64,119.09l-1.24-.94,1.07-1.09a23.42,23.42,0,0,0,6.72-16.17c0-13.81-12.72-25-28.34-25H0v89.92H44.58c16.53,0,30-11.88,30-26.49,0-7.78-3.86-15.15-10.6-20.19M15.71,89H42.17c7.36,0,13.34,5.34,13.34,11.9s-6,11.89-13.34,11.89H15.71Zm28.87,63.64H15.71V125.92H44.58c8.26,0,15,6,15,13.36s-6.72,13.35-15,13.35"/>
                    <polygon class="neutral" points="336.87 135.84 310.87 75.85 293.56 75.85 333.24 165.78 340.49 165.78 380.17 75.85 362.86 75.85 336.87 135.84"/>
                    <path class="neutral" d="M137.21,74.06A46.76,46.76,0,1,0,184,120.81,46.75,46.75,0,0,0,137.21,74.06Zm0,78.11a31.36,31.36,0,1,1,31.35-31.36A31.39,31.39,0,0,1,137.22,152.17Z"/>
                    <polygon class="neutral" points="261.56 133.83 210.52 75.85 202.69 75.85 202.69 165.78 218.38 165.78 218.38 107.8 269.42 165.78 277.25 165.78 277.25 75.85 261.56 75.85 261.56 133.83"/>
                    <path class="neutral" d="M425.28,74.06a31.36,31.36,0,1,0,31.36,31.36A31.4,31.4,0,0,0,425.28,74.06Zm0,49.43a18.08,18.08,0,1,1,18.07-18.07A18.1,18.1,0,0,1,425.28,123.49Z"/>
                    <polygon class="neutral" points="85.03 15.45 85.03 45.87 76.64 45.87 76.64 0.89 76.65 0.89 76.64 0.89 86.03 0.89 99.89 25.79 113.75 0.89 123.14 0.89 123.14 0.89 123.14 0.89 123.14 45.87 114.76 45.87 114.76 15.45 102.92 37.23 96.87 37.23 85.03 15.45"/>
                    <polygon class="neutral" points="443.65 0.89 443.65 8.64 456.92 8.64 456.92 45.87 465.3 45.87 465.3 8.64 478.57 8.64 478.57 0.89 443.65 0.89"/>
                    <polygon class="neutral" points="395.1 0.89 395.1 8.64 408.36 8.64 408.36 45.87 416.75 45.87 416.75 8.64 430.01 8.64 430.01 0.89 395.1 0.89"/>
                    <rect class="neutral" x="311.48" y="0.89" width="8.39" height="44.98"/>
                    <path class="neutral" d="M208.19,27.66H215L227.6,45.88h9.62l-12.7-18.41c7.19-1,12.7-6.54,12.7-13.2h0c0-7.38-6.78-13.39-15.11-13.39H199.8v45h8.39Zm0-19.66h13.92c3.9,0,7.08,2.81,7.08,6.27h0c0,3.46-3.18,6.28-7.08,6.28H208.19Z"/>
                    <path class="neutral" d="M162.07,15.89,168.61,30H155.52Zm-2-15-20.84,45h9l4-8.64H172l4,8.64h9L164.11.89Z"/>
                    <path class="neutral" d="M277.3.88H255v45h8.39V27.66h6.84l12.57,18.22h9.62l-12.7-18.41c7.19-1,12.7-6.54,12.7-13.2h0c0-7.38-6.78-13.39-15.11-13.39m7.08,13.39h0c0,3.46-3.17,6.27-7.08,6.27H263.38V8H277.3c3.91,0,7.08,2.81,7.08,6.27Z"/>
                    <path class="neutral" d="M360.15,0a23.38,23.38,0,1,0,23.38,23.38A23.38,23.38,0,0,0,360.15,0Zm0,38.38a15,15,0,1,1,15-15A15,15,0,0,1,360.15,38.38Z"/>
                    <path class="neutral" d="M564.81,77.07h-3.57V75.7h8.69v1.37h-3.57V87h-1.55Z"/>
                    <path class="neutral" d="M572.73,87h-1.41V75.7h2.2l2.81,8.67a6.88,6.88,0,0,1,.26.93,8.14,8.14,0,0,1,.26-.93l2.91-8.67H582V87h-1.5V77.37a7.67,7.67,0,0,1-.27,1L577.29,87h-1.45L573,78.36a7.16,7.16,0,0,1-.26-1Z"/>
                  </g>
                </svg>
              </div>
            </div>
          </lobster-layout>
          <lobster-layout id="secondary-menu" width="33" data-justify="flex-end">
            <a href="https://www.marriott.com" class="link-secondary custom_click_track" 
            custom_click_track_value="Header|Marriott.com Link|external">
              Marriott.com
            </a>
            <a href="https://www.marriott.com/sign-in.mi" target="_blank" class="link-secondary sign-in custom_click_track"
            custom_click_track_value="Header|Sign In or Join|external">
              Sign In or Join
            </a>
          </lobster-layout>
          <lobster-layout id="mobile-menu" width="33" data-justify="flex-end">
            <div class="mobile-menu-button" @click="toggleMobileForm">
              <svg viewBox="0 0 100 80" width="18" height="14">
                <rect width="100" height="10" rx="0"></rect>
                <rect y="30" width="100" height="10" rx="0"></rect>
                <rect y="60" width="100" height="10" rx="0"></rect>
              </svg>
            </div>
          </lobster-layout>
        </lobster-layout>
        <lobster-layout id="main-menu" data-direction="row" data-container="centered" data-justify="flex-start">
          <template v-for="(link, index) in links">
              <a class="link custom_click_track" :key="link.id" :href="link.url" :custom_click_track_value="link.tracking">
                {{link.displayText}}
              </a>
          </template>
        </lobster-layout>

        <div class="mobile-header-container" :class="{ active: formIsActive }">
          <lobster-layout data-direction="column" data-container="centered">
            <button class="back" @click.prevent="toggleMobileForm">&#8592;</button>
            <template v-for="(link, index) in links">
              <a class="link custom_click_track" :key="link.id" :href="link.url" :custom_click_track_value="link.tracking">
                {{link.displayText}}
              </a>
            </template>
            <a href="https://www.marriott.com" class="link custom_click_track" 
            custom_click_track_value="Header|Marriott.com Link|external">
              Marriott.com
            </a>
            <a href="https://www.marriott.com/sign-in.mi" class="link sign-in custom_click_track" 
            custom_click_track_value="Header|Sign In or Join|external">
              Sign In or Join
            </a>
          </lobster-layout>
        </div>
    </div>
</template>

<script>
import headerLinks from '@/data/headerLinks'

export default {
    name: 'header-bar',
    data() {
        return { links: [], formIsActive: false, }
    },
    props: {
    },
    mounted(){
        this.links = headerLinks;
    },
    methods: {
      toggleMobileForm() {
        if (this.formIsActive) {
          this.formIsActive = false;
          document.body.classList.remove("scroll-lock");
        } else {
          this.formIsActive = true;
          document.body.classList.add("scroll-lock");
        }
      },
    }
}
</script>

<style lang="scss">
@import '../../node_modules/@hlk/lobster/lobster-global-theme/_variables.scss';
@import '../../node_modules/@hlk/lobster/lobster-global-theme/_mixins.scss';

.right{
    text-align: right;
}

.header-container{
    width: 100%;
    background-color: #1c1c1c;
    color: #FFFFFF;
    padding-top: 14px;
    padding-bottom: 14px;
}

.header-container .right > *{
    padding: 10px;
}

.header-container a{
    color: inherit;
    text-decoration: none;
}

.links-container{
    line-height: 50px;
}

.link{
  padding: 10px;
  position: relative;  
}

.link:hover:before{
  @include gt-md{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: #ff9662;
  }
}

.link-secondary {
  color: #d2d2d2!important;
  margin-left: 30px;
  &:hover{
    color: #ffffff !important;
  }

  &.sign-in {
    padding-left: 20px;
    position: relative;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 20px;
      content: "";
      background-image: url('/img/icons/account-icon-small.svg');
      background-position: 0px 2px;
      background-repeat: no-repeat;
    }
  }
  &.globe {
    padding-left: 25px;
    position: relative;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 20px;
      content: "";
      background-image: url('/img/icons/Icon-Globe.svg');
      background-position: 0px 2px;
      background-repeat: no-repeat;
    }
  }
}

.portfolio__logo {
  fill: #fff;
  width: 100px;
  height: auto;
  margin-bottom: 18px;
}

#secondary-menu, #main-menu {
  @include lt-md {
    display: none;
  }
}

.mobile-menu-button{
  display:flex;
  align-items: center;
}

.mobile-menu-button svg{
  fill: #ffffff;
}

.mobile-header-container {
  display: none;
  opacity: 0;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 100vw;
  width: 100vw;
  height: 100vh;
  background-color: #1c1c1c;
  transition: all 0.5s ease-in-out;

  @include lt-md {
    display: block;
    &.active {
      position: inherit;
      opacity: 1;
      left: 0;
      transition: all 0.2s ease-in-out;
    }
    transition: opacity 0.5s ease-in-out;
  }

  .back {
    margin-top: 20px;
    font-size: 24px;
    color: #fff;
    outline: none;
    background: none;
    border: 0;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
  }
}

.mobile-only{
  display: none;

  @include lt-md{
    display: block;
  }

  .svg-container{
    position: absolute;
    top: 0;
    left: 50%;
    width: 125px;
    height: 55px;
    z-index: 999;
    padding: 12px 15px 15px;
    transform: translate(-50%);
    background-color: black;

    .portfolio__logo{
      width: 100%;
      overflow: visible;
      z-index: 999;
    }
  }

}

.desktop-only{
  display: block;

  .portfolio__logo{
    margin-left: 10px;
  }

  @include lt-md{
    display: none;
  }
}
</style>
