<template>
  <div class="homes-villas-search">
    <lobster-layout data-container="center" data-direction="row" data-wrap="true" data-justify="space-between">
        <lobster-layout width="100" width-md="31" width-lg="31">
          <lobster-select
            data-label="Location"
            :setter.prop="setSearchTerm"
            :data-options.prop="options"
          ></lobster-select>
        </lobster-layout>
        <lobster-layout width="100" width-md="31" width-lg="31" data-direction="row" data-wrap="true" data-justify="space-between">
          <lobster-label width="100">Date (Min. 3 Night Stay)</lobster-label>
          <lobster-layout width="46" data-direction="row" data-wrap="false">
            <lobster-datepicker
              class="datepicker-from"
              data-label=" "
              data-placeholder="From"
              mode="single"
              :setter.prop="setFromDate"
              close-on-set-date="true"
            >
            </lobster-datepicker>
          </lobster-layout>
          <lobster-layout width="46" data-direction="row" data-wrap="false">
            <lobster-datepicker
              class="datepicker-to"
              data-placeholder="To"
              mode="single"
              :setter.prop="setToDate"
              close-on-set-date="true"
            >
            </lobster-datepicker>
          </lobster-layout>
        </lobster-layout>
        <lobster-layout width="100" width-md="31" width-lg="31">
          <a class="button submit custom_click_track" @click.prevent="go"
          custom_click_track_value="Search Widget - HVMI|Find a Hotel|external">
              Find a Home
          </a>
        </lobster-layout>
        <lobster-layout width="100">
          <a href="https://homes-and-villas.marriott.com" class="all-destinations">See all destinations</a>
        </lobster-layout>
    </lobster-layout>
  </div>
</template>

<script>
import * as R from 'ramda'
import moment from 'moment'
import FuzzySet from 'fuzzyset.js'

import typeahead from '@/components/search-parts/search-ui/typeahead'

import data from '@/data/homesVillas.json'

export default {
    name: 'homes-villas-search',
    components: {
      typeahead
    },
    mounted(){
      // this.propertySearch = FuzzySet(R.map(R.prop('name'), this.properties))
    },
    data() {
      return {
        searchTerm: null,
        fromDate: null,
        toDate: null,
        results: [],
        searchUrl: null,
        options: [
          {
            label: 'Select',
            value: null
          },
          {
            label: 'All Destinations',
            value: '/en/destinations'
          },
          {
            label: 'Orlando-Kissimmee, FL',
            value: '/en/search/orlando-kissimmee-home-and-villa-rental'
          },
          {
            label: 'Austin, TX',
            value: '/en/search/vacation-rental-austin'
          },
          {
            label: 'Nashville, TN',
            value: '/en/search/vacation-rental-nashville'
          },
          {
            label: 'London, England',
            value: '/en/search/vacation-rental-london'
          },
          {
            label: 'Paris, France',
            value: '/en/search/paris-villa-rental'
          },
          {
            label: 'Hilton Head Island, South Carolina',
            value: '/en/search/hilton-head-island-home-and-villa-rental'
          },
          {
            label: 'Palm Springs, California',
            value: '/en/search/palm-springs-vacation-rental'
          },
          {
            label: 'Savannah, Georgia',
            value: '/en/search/vacation-rental-savannah'
          },
        ],
        properties: data,
      }
    },
    computed: {
      // searchUrl() {
      //   if (this.searchTerm) {
      //     return R.find(R.propEq('name', this.searchTerm), this.properties)
      //   }
      //   return null
      // },
      getToDate() {
        return this.toDate
      },
    },
    methods: {
      setSearchTerm(term) {
        if (term) {
          this.searchUrl = term
        } else {
          this.searchUrl = '/en/destinations'
        }
      },
      setFromDate(date) {
        const dateObj = moment(date, "MM-DD-YYYY")
        this.fromDate = dateObj.format('YYYY-MM-DD')
        // const toDate = dateObj.add(3, 'days').format('YYYY-MM-DD')
        // this.toDate = toDate

        let toDateField = document.querySelector('.datepicker-to input')
        toDateField.focus()
      },
      setToDate(date) {
        const dateObj = moment(date, "MM-DD-YYYY")
        this.toDate = dateObj.format('YYYY-MM-DD')
      },
      go() {
        const path = this.searchUrl
        const url = `https://homes-and-villas.marriott.com${path}?fromDate=${this.fromDate}&toDate=${this.toDate}`
        window.open(url, '_blank')
      }
    },
}
</script>

<style lang="scss">
@import '../../../node_modules/@hlk/lobster/lobster-global-theme/_variables.scss';
@import '../../../node_modules/@hlk/lobster/lobster-global-theme/_mixins.scss';

.homes-villas-search > lobster-layout > lobster-layout {
  @include lt-md {
    margin-bottom: 20px;
  }
}
.homes-villas-search  {
  .datepicker-to { margin-top: 0; }
  lobster-select lobster-label {
    margin-left: 20px;
    position: relative;
    &:before {
      position: absolute;
      content:"";
      height: 100%;
      width: 20px;
      top: 0;
      left: -22px;
      background-image: url('/img/icons/location-icon-small.svg');
      background-position: center center;
      background-repeat: none;
    }
  }
  lobster-layout > lobster-label {
    margin-left: 20px;
    position: relative;
    &:before {
      position: absolute;
      content:"";
      height: 100%;
      width: 20px;
      top: 0;
      left: -22px;
      background-image: url('/img/icons/calendar-icon-small.svg');
      background-position: center center;
      background-repeat: none;
    }
  }
  lobster-select {
    width: 100%;
    select {
      background-color: transparent;
      @include lt-md {
        color: #fff;
      }
      margin-top: 3px;
    }
  }

  .all-destinations {
    text-decoration: underline;
  }
}

.all-destinations {
  margin-top: 25px;
  text-decoration: none;
  color: #1c1c1c;
  font-size: 18px;
  font-family: "Swiss721BT-Regular", sans-serif;
}

</style>
