<template>
  <lobster-layout data-container="centered">
    <div class="pop-out-container">
      <div class="pop-out">
        <div class="side">
          <div class="content">
            <div class="eyebrow">Travel with confidence</div>
            <div class="title headline-1">
              Elevating our high standards to bring you peace of mind
            </div>
            <div class="subcontent lobster_layout_margin-bottom--lg body">
              With our new Marriott Commitment to Clean program, we’ve taken
              significant steps to ensure all our properties meet rigorous
              standards. You can rest easy knowing that we’ve gone the extra
              mile to ensure your stay is as safe and enjoyable as possible.
              Less worry, more memories.
            </div>
            <lobster-link
              data-variation="button"
              data="See Our Pledge"
              data-href="https://clean.marriott.com/"
            ></lobster-link>
          </div>
        </div>
        <div class="image-side side">
          <img src="/img/commitmenttoclean.jpg" alt="Collage of employees in masks with gray map print in background, Marriott's commitment to clean" />
        </div>
      </div>
    </div>
  </lobster-layout>
</template>

<script>
export default {
  name: "pop-out",
  data() {
    return {};
  },
  props: {},
  mounted() {},
  methods: {},
  components: {},
};
</script>

<style lang="scss">
.pop-out-container {
  background-color: white;
  padding-bottom: 80px;
}

.pop-out-container a {
  text-decoration: none;
}

.pop-out {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.07);
  display: flex;
}

.content {
  flex: 1;
  margin: 60px 32px;
}

.pop-out .eyebrow {
  color: #707070;
}

.pop-out .title {
  margin: 5px 0 15px;
}

.pop-out .subcontent {
  width: 75%;
  line-height: 1.5;
  font-size: 16px;
  font-family: "Swiss721BT-Regular";
}

.pop-out .side {
  flex: 1;
}

.pop-out .image-side img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 950px) {
  .pop-out {
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
    align-items: center;

    .content{
      margin: 60px 25px 24px;
    }

    .title {
      font-size: 22px;
    }

    .subcontent {
      width: 100%;
    }
  }
}
</style>