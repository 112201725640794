<template>
  <div class="yacht-search">
    <lobster-layout data-container="center" data-direction="row" data-wrap="true" data-justify="space-between">
        <lobster-layout width="100" width-md="63" width-lg="63">
          <lobster-select
            data-label="Region"
            :setter.prop="setLocation"
            :data-options.prop="locationOptions"
          ></lobster-select>
        </lobster-layout>
        <lobster-layout width="100" width-md="31" width-lg="31">
          <a class="button submit custom_click_track" @click.prevent="go"
          custom_click_track_value="Search Widget - RZ Yacht|Find a Hotel|external">
              Find a Cruise
          </a>
        </lobster-layout>
    </lobster-layout>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import * as R from 'ramda'

import data from '@/data/yachts.json'

// https://www.ritzcarltonyachtcollection.com/find-a-cruise?region=CANE&date=2021_09&port=PTLIS

const voyages = R.path(['data', 'voyages'], data)

const ports = R.uniq(R.flatten(R.map((item) => {
  const ports = R.prop('portData', item)
  return R.map((port) => {
    return {
      label: R.prop('portName', port),
      value: R.prop('portCode', port)
    }
  }, ports)
}, voyages)))

const dates = () => {
 const months = []
 const dateStart = moment()
 const dateEnd = moment().add(11, 'months')
 while (dateEnd.diff(dateStart, 'months') >= 0) {
   months.push({
     label: dateStart.format('MMMM YYYY'),
     value: dateStart.format('YYYY_MM')
   })
   dateStart.add(1, 'month')
 }
 return months
}


export default {
    name: 'yacht-search',
    props: {
      'setLoaded': Function
    },
    mounted(){
    },
    data() {
      return {
        locationOptions: [
          {
            label: "Select",
            value: null
          },
          {
            label: "All Regions",
            value: "CANE,CARB,MEDD,TRAN"
          },
          { value: "CANE", label: "Canada & New England"},
          { value: "CARB", label: "Caribbean"},
          { value: "MEDD", label: "Mediterranean"},
          { value: "TRAN", label: "Transatlantic"},
        ],
        dateOptions: [
          {
            label: "Select",
            value: null
          },
          ...dates()
        ],
        portOptions: [
          {
            label: "Select",
            value: null
          },
          ...ports
        ],
        location: null,
        date: null,
        port: null,
      }
    },
    methods: {
      setLocation(location) {
        this.location = location
      },
      setDate(date) {
        this.date = date
      },
      setPort(port) {
        this.port = port
      },
      go() {
        const url = `https://www.ritzcarltonyachtcollection.com/find-a-cruise?region=${this.location}`
        window.open(url, '_blank')
      }
    },
}
</script>

<style lang="scss">
@import '../../../node_modules/@hlk/lobster/lobster-global-theme/_variables.scss';
@import '../../../node_modules/@hlk/lobster/lobster-global-theme/_mixins.scss';

.yacht-search > lobster-layout > lobster-layout {
  @include lt-md {
    margin-bottom: 20px;
  }
}

.yacht-search lobster-datepicker {
  lobster-label {
    margin-left: 20px;
    position: relative;
    &:before {
      position: absolute;
      content:"";
      height: 100%;
      width: 20px;
      top: 0;
      left: -22px;
      background-image: url('/img/icons/calendar-icon-small.svg');
      background-position: center center;
    }
  }
}

.yacht-search lobster-select {
  margin-top: 22px;
  min-width: 100%;

  select {
    background-color: transparent;
    @include lt-md {
      color: #fff;
    }
  }
}

.yacht-search .submit.button{
    margin: 40px 0 10px 0;
    background-color: black;
    color: white;
    height: 50px;
    border-radius: 25px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;

}
</style>
