<template>
  <a :href="info.url">
    <div class="carousel-card">
      <lobster-image
        :url="info.image"
        :image-alt="info.alt"
        aspect-ratio="350-400"
      ></lobster-image>
      <div class="text-container">
        <div class="eyebrow">{{ info.eyebrow }}</div>
        <div class="title headline-3">{{ info.title }}</div>
      </div>
      <div class="chevron"><i aria-hidden="true" class="material-icons">chevron_right</i></div>
    </div>
  </a>
</template>

<script>
export default {
  name: "carousel-card",
  data() {
    return {};
  },
  props: {
    info: {},
  },
  methods: {},
};
</script>

<style lang="scss">
.carousel-card {
  flex: 1;
  border-radius: 20px;
  overflow: hidden;
  text-decoration: none;
  color: white;
  background-color: black;
  position: relative;
  margin: 10px auto;
  transition: box-shadow 0.25s ease-in;
}

.carousel-card:hover {
  lobster-image div{
    transform: scaleX(1.1) ScaleY(1.1);
    transform-origin: center;
    transition: transform 0.25s linear;
  }
}

.carousel-card:after {
  content: "";
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 20px;
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0.25)),
    color-stop(100%, rgba(255, 255, 255, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  ); /* W3C */
}

.carousel-card img {
  display: block;
  width: 100%;
  height: 100%;
  transform: scaleX(1) ScaleY(1);
  transition: transform 0.25s linear;
}

.carousel-card {
  lobster-image div{
    transform: scaleX(1) ScaleY(1);
    transition: transform 0.25s linear;
  }
}

.carousel-card .text-container {
  position: absolute;
  left: 0;
  bottom: 32px;
  text-align: left;
  width: 80%;
  z-index: 99;

  .title {
    margin-left: 32px;
    left: 0;
    transition: top 0.25s ease-in;
  }

  .eyebrow {
    margin-left: 32px;
    font-family: Swiss721BT-Medium;
    font-size: 12px;
  }
}

.chevron {
  position: absolute;
  right: 32px;
  bottom: 24px;
  text-align: left;
  font-size: 22px;
  z-index: 99;
}

</style>