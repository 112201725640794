<template>
  <div class="next-vacation">
    <sectionHeader :title="title" :viewAllUrl="viewAllUrl"> </sectionHeader>
    <div class="card-container">
      <lobster-layout data-container="centered">
        <lobster-carousel
          disabled-md="true"
          navigation-position="below"
          slide-width-md="16"
          slide-width-sm="50"
          slide-width="50"
          gutter="small"
        >
          <lobster-carousel-slide
            v-for="(card, index) in cards"
            slot="lobster-carousel-slide"
          >
            <image-card :key="index" :info="card"></image-card>
          </lobster-carousel-slide>
        </lobster-carousel>
      </lobster-layout>
    </div>
  </div>
</template>

<script>
import nextVacation from "@/data/nextVacation";
import imageCard from "@/partials/image-card";
import SectionHeader from "../partials/section-header.vue";

export default {
  name: "next-vacation",
  data() {
    return {
      title: "What kind of trip are you looking for?",
      cards: [],
      carouselView: true,
      viewAllUrl: "https://www.marriott.com/explore/default.mi",
    };
  },
  props: {},
  mounted() {
    this.cards = nextVacation;
    this.resizeHandler();
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    resizeHandler() {
      this.carouselView = window.innerWidth <= 1250;
    },
  },
  computed: {},
  components: {
    imageCard,
    SectionHeader,
  },
};
</script>

<style lang="scss">
@import "../../node_modules/@hlk/lobster/lobster-global-theme/_variables.scss";
@import "../../node_modules/@hlk/lobster/lobster-global-theme/_mixins.scss";

.next-vacation {
  padding: 80px 0;
  @include lt-md {
    // account for hero form vertical offset
    padding-top: 100px;
  }
  background-color: #f5f5f5;

  .heading {
    text-align: center;
    width: 100%;
  }
  @media screen and (max-width: 950px) {
    .lobster_layout_flex--50 {
      flex: 0 0 45%;
    }
  }

  .lobster_carousel-slides-wrapper {
    flex-wrap: nowrap !important;

    .lobster_layout_flex--16 {
      width: 60%;
      flex: auto;
    }
  }

  .card-container .lobster_layout_container--large-centered {
    @include lt-md {
      max-width: 100%;
      margin-right: -10px;
      padding-right: 10px;
    }
  }

  lobster-layout:first-of-type {
    position: relative;
  }
}

.next-vacation .card-container {
  width: 100%;
  display: inline-block;

  > lobster-layout {
    @media (max-width: 960px) {
      margin-right: 0;
    }
  }
}

@media screen and (min-width: 1200px) {
  .next-vacation .card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }
}
</style>
