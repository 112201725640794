import * as R from "ramda"
import properties from '@/data/marriott_properties_ALL_BRANDS_en_US.json'


// Helper Functions
const getPropData = (prop) => R.map((item) => {
  return R.prop(prop, item)
})

const getCountries = R.compose(R.flatten, getPropData('region_countries'))
const getStates = R.compose(R.flatten, getPropData('country_states'))
const getCities = R.compose(R.flatten, getPropData('state_cities'))
const getProperties = R.compose(R.flatten, getPropData('city_properties'))

const recursiveCrawl = (data) => {
  return R.compose(getProperties, getCities, getStates, getCountries)(data)
}

// Exports

export const getAllProperties = (data) => {
  return recursiveCrawl(data.regions)
}

export const getPropertiesByCode = (code, data) => {
  return R.find(R.propEq('marsha_code', code), getAllProperties(data))
}

export const getPropertiesByCodeList = (codes, data) => {
  return R.filter(
    (property) => R.includes(property.marsha_code, codes),
    getAllProperties(data)
  )
}

export const allProperties = getAllProperties(properties)
export const propertyData = properties
