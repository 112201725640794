<template>

    <div class="autocomplete-container">
        <div style="position:relative" v-bind:class="{'open':openSuggestion}">
            <input class="input-form" type="text" v-model="selection"
                @keydown.enter = 'enter'
                @keydown.down = 'down'
                @keydown.up = 'up'
                @input = 'change'
            />
            <ul class="dropdown-menu">
                <li v-for="(suggestion, index) in matches" :key="index"
                    class="dropdown-item"
                    v-bind:class="{'active': isActive(index)}"
                    @click="suggestionClick(index)"
                >
                <a href="#">{{ suggestion }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>


export default {
    name: 'autocomplete',
    data() {
        return {
            open: false,
            index: 0,
            selection: String
        }
    },
    props: {
        suggestions: {
            type: Array
        },
    },
    computed: {
        //Filtering the suggestion based on the input
        matches() {
            if(this.suggestions){
                return this.suggestions.filter((str) => {
                    if(typeof this.selection === 'string') 
                        return str.toLowerCase().indexOf(this.selection) >= 0;
                    return true;
                });
            }
            
            return [];
        },

        //The flag
        openSuggestion() {
            return this.selection !== "" &&
                this.matches.length != 0 &&
                this.open === true;
        }
    },
    mounted(){
        this.selection = '';
    },
    methods: {
        //When enter pressed on the input
        enter() {
            this.selection = this.matches[this.index];
            this.open = false;
        },

        //When up pressed while suggestions are open
        up() {
            if(this.index > 0)
                this.index--;
        },

        //When up pressed while suggestions are open
        down() {
            if(this.index < this.suggestions.length - 1)
                this.index++;
        },

        //For highlighting element
        isActive(index) {
            return index === this.index;
        },

        //When the user changes input
        change() {
            if (this.open == false) {
                this.open = true;
                this.index = 0;
            }
        },

        //When one of the suggestion is clicked
        suggestionClick(index) {
            this.selection = this.matches[index];
            this.open = false;
        },
    }
}
</script>

<style lang="scss">

.input-form{
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}

.open .dropdown-menu {
    display: block;
}

.dropdown-item {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.dropdown-item a{
    text-decoration: none;
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.dropdown-item.active a{
    color: #fff;
    text-decoration: none;
    background-color: #337ab7;
    outline: 0;
}



</style>