<template>
  <div class="hero-container" :class="{ loaded: loaded }">
    <div class="background-container">
      <div
        v-for="(image, index) in photos"
        class="background"
        :style="backgroundStyles(image, index)"
      ></div>
    </div>
    <div class="content-container">
      <lobster-layout
        data-container="centered"
        data-align="center"
        data-wrap="true"
      >
        <lobster-layout
          width="100"
          width-md="25"
          width-lg="25"
          data-align="center"
        >
          <hero-content :info="content"></hero-content>
        </lobster-layout>
        <lobster-layout
          width="100"
          width-md="75"
          width-lg="75"
          data-align="center"
        >
          <tabs-holder
            :tabs="data"
            :getter="() => activeTab"
            :setter="setActiveTab"
          ></tabs-holder>
        </lobster-layout>
      </lobster-layout>
    </div>
  </div>
</template>

<script>
import * as R from "ramda";
import heroJson from "@/data/hero";

import heroContent from "@/components/hero-parts/hero-content";
import tabsHolder from "@/components/hero-parts/tabs-holder";

export default {
  name: "hero",
  data() {
    return {
      data: heroJson,
      activeTab: 0,
      loaded: false,
    };
  },
  mounted() {
    this.loaded = true;
  },
  computed: {
    photos() {
      return R.map(R.prop("backgroundImage"), this.data);
    },
    content() {
      return this.data[this.activeTab];
    },
  },
  methods: {
    setActiveTab(data) {
      this.activeTab = data;
    },
    backgroundStyles(image, index) {
      return {
        backgroundImage: `url('${image}')`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        opacity: index === this.activeTab ? 1 : 0,
      };
    },
  },
  components: {
    heroJson,
    heroContent,
    tabsHolder,
  },
};
</script>

<style lang="scss">
@import "../../node_modules/@hlk/lobster/lobster-global-theme/_variables.scss";
@import "../../node_modules/@hlk/lobster/lobster-global-theme/_mixins.scss";

.hero-animate {
  @include gt-md {
    .hero-content {
      transform: translateX(-80px);
      opacity: 0;
      transition: all 0.95s ease-in-out;
    }
    .holder {
      transform: translateY(20px);
      opacity: 0;
      transition: all 0.85s ease-in-out;
    }
    &.in-viewport {
      .hero-content {
        transform: translateX(0px);
        opacity: 1;
        transition: all 0.95s ease-in-out;
      }
      .holder {
        transform: translateY(0px);
        opacity: 1;
        transition: all 0.85s ease-in-out;
      }
    }
  }
}

.background-container {
  width: 100%;
  height: calc(100vh - 200px);
  max-height: 90vh;
  transition: opacity 0.5s linear;
  background-color: #000;
  @include lt-md {
    height: calc(100vh - 200px);
  }
}

.background {
  position: absolute;
  width: 100%;
  height: calc(100vh - 200px);
  max-height: 90vh;
  opacity: 0;
  transition: opacity 0.5s linear;
  @include lt-md {
    height: calc(100vh - 200px);
  }
}

.background.active {
  display: block;
}

.background::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.6) 20%,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0)
  );
}
.hero-container {
  position: relative;
  min-height: 50vh;
  z-index: 998;
  @include lt-md {
    height: calc(100vh - 200px);
  }
}

.hero-container .content-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
}

.content-side {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.tabs-side {
  flex: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.content-container .lobster_layout_container--large-centered {
  @include lt-md {
    max-width: 100%;
    width: 100%;
  }
}
</style>
