<template>
  <span class="title-line">
    {{ title }}
  </span>
</template>

<script>
export default {
  name: "title-line",
  data() {
    return {};
  },
  props: {
    title: String,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.title-line {
  display: inline-block;
  //   width: 75%;
  margin: 0 auto;
  position: relative;
  text-align: center;
  font-family: Swiss721BT-Regular;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #1c1c1c;
  //   margin-bottom: 30px;
}

@media screen and (max-width: 600px) {
  .title-line {
    font-size: 22px;
    width: 100%;
    display: block;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
