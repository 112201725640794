<template>
  <div class="side-by-side">
    <sectionHeader :title="title"></sectionHeader>
    <div class="side-by-side-content">
      <lobster-layout
        data-container="centered"
        data-direction="column"
        direction-md="row"
      >
        <template v-for="(card, index) in cards">
          <lobster-layout :key="index" width="100" width-md="50">
            <tall-card :info="card"></tall-card>
          </lobster-layout>
        </template>
      </lobster-layout>
    </div>
  </div>
</template>

<script>
import sectionHeader from "@/partials/section-header";
import tallCard from "@/partials/tall-card";
import sideBySide from "@/data/sideBySide";

export default {
  name: "private-homes",
  data() {
    return { title: "Imagine the possibilities", cards: [] };
  },
  props: {},
  mounted() {
    this.cards = sideBySide;
  },
  methods: {},
  components: {
    tallCard,
    sectionHeader,
  },
};
</script>

<style lang="scss">
.side-by-side {
  padding: 80px 0;
}

.heading {
  text-align: center;
  width: 100%;
}

.side-by-side-content {
  @media (max-width: 950px) {
    > lobster-layout > lobster-layout {
      + lobster-layout {
        margin-top: 20px;
      }
    }
  }
  @media (min-width: 960px) {
    > lobster-layout > lobster-layout {
      &:first-child {
        padding-right: 10px;
      }
      &:last-child {
        padding-left: 10px;
      }
    }
  }
}
</style>