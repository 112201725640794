<template>
  <div class="hero-tabs-wrapper">
    <div class="holder">
      <div class="tabs-container">
        <template v-for="(tab, index) in tabs">
          <div
            v-on:click.prevent="setter(index)"
            class="tab"
            :class="{ active: index === getter() }"
            :key="index"
          >
            <span class="desktopOnly">{{ tab.name }}</span>
            <span class="mobileOnly">{{ tab.mobileName }}</span>
          </div>
        </template>
      </div>
      <div class="schedule-container">
        <component :is="activeComponent" :setLoaded="setLoaded"></component>
      </div>
      <div class="mobile-schedule-container" @click="toggleMobileForm">
        <lobster-layout
          data-direction="row"
          data-justify="flex-start"
          data-align="center"
        >
          <lobster-layout
            width="50"
            data-direction="column"
            data-justify="flex-start"
          >
            <lobster-label class="location"> Location </lobster-label>
            <div class="location-text">Los Angeles</div>
          </lobster-layout>
          <lobster-layout
            width="50"
            data-direction="column"
            data-justify="flex-start"
          >
            <lobster-label class="dates"> Dates </lobster-label>
            <div class="dates-text">Mar 15 - 16</div>
          </lobster-layout>
        </lobster-layout>
      </div>
    </div>

    <div class="mobile-form-container" :class="{ active: formIsActive }">
      <lobster-layout data-direction="column" data-container="centered">
        <button class="back" @click.prevent="toggleMobileForm">&#8592;</button>
        <h2>Where do you want to go?</h2>
        <component :is="activeComponent" :setLoaded="setLoaded"></component>
      </lobster-layout>
    </div>
  </div>
</template>

<script>
import * as R from "ramda";
import autocomplete from "@/components/hero-parts/autocomplete";
import hotelsSearch from "@/components/search-parts/marriott-hotels-search";
import homesVillasSearch from "@/components/search-parts/homes-villas-search";
import vacationsPackagesSearch from "@/components/search-parts/vacations-packages-search";
import yachtSearch from "@/components/search-parts/yacht-search";
import activitiesSearch from "@/components/search-parts/activities-search";

export default {
  name: "tabs-holder",
  data() {
    return {
      loaded: false,
      formIsActive: false,
    };
  },
  props: {
    tabs: Array,
    getter: Function,
    setter: Function,
  },
  methods: {
    setLoaded(data) {
      this.loaded = data;
    },
    toggleMobileForm() {
      if (this.formIsActive) {
        this.formIsActive = false;
        document.body.classList.remove("scroll-lock");
      } else {
        this.formIsActive = true;
        document.body.classList.add("scroll-lock");
        const el = document.querySelector('.mobile-form-container .typeahead input');
        el.focus()
      }
    },
  },
  computed: {
    activeComponent() {
      const map = [
        "hotels-search",
        "homes-villas-search",
        "vacations-packages-search",
        "yacht-search",
        "activities-search",
      ];
      return map[this.getter()];
    },
  },
  components: {
    autocomplete,
    hotelsSearch,
    homesVillasSearch,
    vacationsPackagesSearch,
    yachtSearch,
    activitiesSearch,
  },
};
</script>

<style lang="scss">
@import "../../../node_modules/@hlk/lobster/lobster-global-theme/_variables.scss";
@import "../../../node_modules/@hlk/lobster/lobster-global-theme/_mixins.scss";

.hero-tabs-wrapper {
  display: flex;
  width: 100%;
}

.holder {
  flex: 1;
  width: 90%;
  border-radius: 25px;
  padding: 25px;
  @include lt-md {
    width: 100%;
    border-radius: 0;
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 110px;
    padding: 0;
    background-color: #1c1c1c;
    > .tabs-container {
      padding-left: 20px;
    }
  }
  @include gt-md {
    padding-right: 0;
  }
}

.tabs-container {
  font-family: Swiss721BT-Medium;
  font-size: 14px;

  color: white;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: nowrap;
  overflow: hidden;
  @include gt-sm {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  @include lt-md {
    overflow: scroll;
    justify-content: flex-start;
  }
}

.tab.active {
  @include gt-md {
    background-color: white;
    color: #1c1c1c;
  }
  @include lt-md {
    background-color: #1c1c1c;
    color: white;
    border-top: 3px solid #ff9662;
  }
}

.tab {
  text-align: center;
  flex: 1;
  background-color: #1c1c1c;
  border-bottom: none;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  padding-top: 15px;
  padding-bottom: 12px;
  @include lt-md {
    flex: none;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 3px solid transparent;
  }
}

.schedule-container {
  background-color: white;
  padding: 40px 25px;
  @include gt-md {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  @include lt-md {
    display: none;
  }
}

.schedule-container .row {
  padding: 25px;
  display: flex;
  justify-content: space-between;
}

.row .third {
  width: 33%;
  padding: 10px 20px;
}

.submit .button {
  margin: 10px 0;
  background-color: black;
  color: white;
  font-size: 0.8em;
  height: 50px;
  border-radius: 25px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.mobile-schedule-container {
  display: none;
  width: calc(100vw - (20px * 2));
  margin: 0 auto;
  height: 100px;
  border-radius: 6px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #eeeeee;
  background-color: #ffffff;
  position: relative;
  @include lt-md {
    display: block;
  }

  & > lobster-layout {
    position: relative;
    height: 100%;
  }

  & > lobster-layout > lobster-layout {
    height: 60px;
    padding-left: 20px;
    padding-right: 40px;
    &:last-of-type {
      border-left: 1px dotted #8d8d8d;
    }
  }

  lobster-label.dates {
    margin-left: 20px;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      height: 100%;
      width: 20px;
      top: 0;
      left: -22px;
      background-image: url("/img/icons/calendar-icon-small.svg");
      background-position: center center;
      background-color: #fff;
    }
  }
  lobster-label.location {
    margin-left: 20px;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      height: 100%;
      width: 20px;
      top: 0;
      left: -22px;
      background-image: url("/img/icons/location-icon-small.svg");
      background-position: center center;
      background-color: #fff;
    }
  }
  .location-text,
  .dates-text {
    padding-top: 10px;
    padding-left: 2px;
    font-family: Swiss721BT-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #1c1c1c;
  }
}
.mobile-form-container {
  display: none;
  opacity: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 100vw;
  width: 100vw;
  height: 100vh;
  background-color: #1c1c1c;
  transition: all 0.5s ease-in-out;

  @include lt-md {
    display: block;
    &.active {
      opacity: 1;
      left: 0;
      transition: all 0.2s ease-in-out;
    }
    transition: opacity 0.5s ease-in-out;
  }

  lobster-label label {
    color: #fff;
  }

  input {
    background-color: transparent !important;
    border-bottom: 2px solid #767676 !important;
    font-size: 18px !important;
  }

  h2 {
    margin: 15px 0px 32px 0px;
    font-family: Swiss721BT-Medium;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.04px;
    color: #ffffff;
  }

  .back {
    margin-top: 20px;
    font-size: 24px;
    color: #fff;
    outline: none;
    background: none;
    border: 0;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
  }
}

.desktopOnly{
  display: inherit;
  @include lt-md {
    display: none;
  }
}

.mobileOnly{
  display: none;
  @include lt-md {
    display: inherit;
  }
}


</style>
