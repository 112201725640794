<template>
  <div class="hotels-search">
    <div id="map"></div>
    <lobster-layout data-container="center" data-direction="row" data-wrap="true" data-justify="space-between">
        <lobster-layout width="100" width-md="31" width-lg="31">
          <typeahead
            label="Location"
            placeholder="Try Cancun"
            :getter="() => this.searchTerm"
            :setter="setSearchTerm"
            :data="results"
          ></typeahead>
        </lobster-layout>
        <lobster-layout width="100" width-md="31" width-lg="31" data-direction="row" data-wrap="false" data-justify="space-between">
          <lobster-layout width="46" data-direction="row" data-wrap="false">
            <lobster-datepicker
              class="datepicker-from"
              data-label="Dates"
              data-placeholder="From"
              mode="single"
              :setter.prop="setFromDate"
              close-on-set-date="true"
            >
            </lobster-datepicker>
          </lobster-layout>
          <lobster-layout width="46" data-direction="row" data-wrap="false">
            <lobster-datepicker
              class="datepicker-to"
              data-placeholder="To"
              mode="single"
              :setter.prop="setToDate"
              close-on-set-date="true"
            >
            </lobster-datepicker>
          </lobster-layout>
        </lobster-layout>
        <lobster-layout width="100" width-md="31" width-lg="31">
          <a class="button submit custom_click_track" @click.prevent="go"
          custom_click_track_value="Search Widget - Hotels|Find a Hotel|external">
              Find a Hotel
          </a>
        </lobster-layout>
    </lobster-layout>
  </div>
</template>

<script>
import * as R from 'ramda'
import axios from 'axios'
import moment from 'moment'
import typeahead from '@/components/search-parts/search-ui/typeahead'

const serialize = function(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export default {
    name: 'hotels-search',
    props: {
      'setLoaded': Function
    },
    components: {
      typeahead,
    },
    data() {
      return {
        searchTerm: null,
        propertySearch: null,
        results: [],
        fromDate: null,
        toDate: null,
      }
    },
    methods: {
      setSearchTerm(term) {
        console.log(term)
        this.searchTerm = term
        if (this.service && this.searchTerm) {
          const params = {
            input: this.searchTerm,
            location: new window.google.maps.LatLng(0, 0),
            radius: 50000,
          }
          console.log(params)
          this.service.getQueryPredictions(params, (res) => {
            this.results = R.map(R.prop('description'), res)
          })
        }
        if (!this.searchTerm) {
          this.results = []
        }
      },
      setFromDate(date) {
        const dateObj = moment(date, "MM-DD-YYYY")
        this.fromDate = dateObj.format('MM/DD/YYYY')

        let toDateField = document.querySelector('.datepicker-to input')
        toDateField.focus()
      },
      setToDate(date) {
        const dateObj = moment(date, "MM-DD-YYYY")
        this.toDate = dateObj.format('MM/DD/YYYY')
      },
      go() {
        const params = serialize({
          searchType: 'InCity',
          showMore: true,
          'destinationAddress.destination': this.searchTerm,
          fromDate: this.fromDate,
          toDate: this.toDate,
        })
        const url = `https://www.marriott.com/search/submitSearch.mi?${params}`
        window.open(url, '_blank')
      }
    },
    computed: {
      service() {
        if (window.google) {
          return new google.maps.places.AutocompleteService();
          // var service = new google.maps.places.AutocompleteService();
          // service.getQueryPredictions({ input: 'pizza near Syd' }, displaySuggestions);
        }
      }
    },
}
</script>

<style lang="scss">
@import '../../../node_modules/@hlk/lobster/lobster-global-theme/_variables.scss';
@import '../../../node_modules/@hlk/lobster/lobster-global-theme/_mixins.scss';

#map {
  display: none;
}

.hotels-search > lobster-layout > lobster-layout {
  @include lt-md {
    margin-bottom: 20px;
  }
}

.hotels-search lobster-datepicker {
  lobster-label {
    margin-left: 20px;
    position: relative;
    &:before {
      position: absolute;
      content:"";
      height: 100%;
      width: 20px;
      top: 0;
      left: -22px;
      background-image: url('/img/icons/calendar-icon-small.svg');
      background-position: center center;
    }
  }
}

.datepicker-to, .datepicker-from {
  width: 100%;
}

.datepicker-to {
  margin-top: 15px;
}

// .submit.button{
//   margin: 10px 0;
//   background-color: black;
//   color: white;
//   font-size: 18px;
//   height: 50px;
//   border-radius: 25px;
//   width: 100%;
//   text-align: center;
//   display: flex;
//   justify-content: center;
//   align-content: center;
//   flex-direction: column;
// }
</style>
