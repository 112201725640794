<template>
  <div class="title-carousel">
    <div v-if="info.logo" class="logo">
      <img :src="info.logo" :alt="info.alt" />
    </div>

    <sectionHeader :title="info.title" :viewAllUrl="info.viewAll">
    </sectionHeader>

    <lobster-layout
      data-container="centered"
      width="100"
      data-direction="block"
      class="lobster_layout_padding-bottom--xl"
    >
      <lobster-carousel
        :navigation-position="navPosition"
        gutter="small"
        slide-width="100"
        slide-width-xs="100"
        slide-width-sm="50"
        slide-width-md="33"
        :slide-width-lg="slideCount()"
      >
        <template v-for="(card, index) in info.cards">
          <lobster-carousel-slide :key="index" slot="lobster-carousel-slide">
            <carousel-card :info="card"></carousel-card>
          </lobster-carousel-slide>
        </template>
      </lobster-carousel>
    </lobster-layout>
  </div>
</template>

<script>
import privateHomes from "@/data/privateHomes";
import carouselCard from "@/partials/carousel-card";
import sectionHeader from "@/partials/section-header";

export default {
  name: "title-carousel",
  data() {
    return { title: "", cards: [], viewAll: "", logo: "", navPosition: "" };
  },
  props: {
    info: {},
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  methods: {
    onResize(){
      this.navPosition = window.innerWidth <= 960 ? 'below' : 'overlay';
    },
    slideCount() {
      // max of four cards visible at a time, if max count of cards
      // in set is less than 4, determine width
      var x = 25;
      if(this.info.cards && this.info.cards.length <= 3){
        var x = 100 / this.info.cards.length;
      }
      return Math.floor(x)
    },
    navigationPosition() {
      if(window.innerWidth <= 960){
        return 'below';
      }
      return 'overlay';
    }
  },
  components: {
    carouselCard,
    sectionHeader,
  },
};
</script>

<style lang="scss">
.title-carousel {
  padding: 80px 0 50px;
  margin: 0 auto;
}

.title-carousel .card-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.title-carousel .logo {
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
}
</style>