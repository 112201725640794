<template>
    <lobster-input
      class="typeahead noautocomplete"
      :data-label.prop="label"
      :placeholder.prop="placeholder"
      :getter.prop="getter"
      :setter.prop="setData"
      @keydown.enter.prevent='enter'
      @keydown.down.prevent='down'
      @keydown.up.prevent='up'
    >
    <span class="clear-input" title="Clear Input" @click="clearInput" v-show="getter()">&#10005;</span>
    <ul class="results" v-show="data && data.length && open">
        <li v-for="(suggestion, index) in data" :key="index"
            class="result"
            v-bind:class="{'active': isActive(index)}"
            @click="suggestionClick(index)"
        >
        <a href="#" v-if="suggestion" v-html="suggestion"></a>
        </li>
    </ul>
    </lobster-input>
</template>

<script>
import * as R from 'ramda'


export default {
    name: 'typeahead',
    props: [
      'label',
      'placeholder',
      'getter',
      'setter',
      'data',
    ],
    data() {
      return {
        open: true,
        index: 0,
      }
    },
    mounted() {
      window.addEventListener('click', this.handleOutsideClick);

      setTimeout(function(){
        var el = document.querySelector('.noautocomplete input');

        if(el)
          el.setAttribute("autocomplete","new-password");
      }, 50);
    },
    destroyed() {
      window.removeEventListener('click', this.handleOutsideClick)
    },
    methods: {
      // handle
      handleOutsideClick(e) {
        if (!document.querySelector('ul.results').contains(e.target)){
          if (this.open) {this.open = false};
        }
      },
      //When enter pressed on the input
      enter() {
        this.setter(this.data[this.index])
        this.open = false;
      },

      //When up pressed while suggestions are open
      up() {
          if(this.index > 0)
              this.index--;
      },

      //When up pressed while suggestions are open
      down() {
          if(this.index < this.data.length - 1)
              this.index++;
      },

      //For highlighting element
      isActive(index) {
          return index === this.index;
      },

      //When the user changes input
      change() {
          if (this.open == false && this.getter()) {
              this.open = true;
              this.index = 0;
          }
          if (this.open && !this.getter()) {
            this.open = false
          }
      },
      setData(data) {
        this.setter(data)
        this.change()
      },

      //When one of the suggestion is clicked
      suggestionClick(index) {
        this.setter(this.data[index], true);
        this.open = false;
        this.index = 0;
      },

      clearInput(){
        this.setData(null);
      }

    },
}
</script>

<style lang="scss">
.typeahead {
  width: 100%;
  position: relative;
  overflow: visible;
}

.typeahead lobster-label {
  margin-left: 20px;
  position: relative;
  &:before {
    position: absolute;
    content:"";
    height: 100%;
    width: 20px;
    top: 0;
    left: -22px;
    background-image: url('/img/icons/location-icon-small.svg');
    background-position: center center;
  }
}

.results {
  list-style-type: none;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: #fff;
  z-index: 999;
  padding: 10px 0 0 0;
  margin: 0;
  li {
    &:last-of-type{
      border-bottom: 1px solid transparent;
    }
    border-bottom: 1px solid #f1f1f1;
    padding: 15px 10px;
    margin: 0;
    a {
      font-family: Swiss721BT-Medium;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.11;
      letter-spacing: normal;
      color: #707070;
      text-decoration: none;
    }

    &.active {
      background-color: #f1f1f1;
    }
  }
}

lobster-input.typeahead > div:first-of-type {
  position: relative;
}

.typeahead .lobster_form_field-wrapper{
  width: 100%;
  display: inline-block;
}

.clear-input{
  display: inline;
  z-index: 50;
  position: absolute;
  right: 0px;
  bottom: 20px;

  @media screen and (max-width: 960px) {
    color: white;
  }
}

.clear-input:hover{
  cursor: pointer;
}
</style>
