<template>
  <div class="footer">
    <lobster-layout data-container="centered" data-direction="row" data-justify="flex-start">
      <span>Copyright &copy; 1996 - 2020 Marriott International, Inc. All rights reserved. Company Proprietary Information</span>
      <span class="dot">&#183;</span>
			<a href="https://www.marriott.com/about/terms-of-use.mi">Terms of Use</a>
      <span class="dot">&#183;</span>
			<a href="https://www.marriott.com/about/privacy.mi">Privacy and Cookie Statement</a>
      <span class="dot">&#183;</span>
      <a id="ccpacontainer"></a>
      <span class="dot">&#183;</span>
      <a id="teconsent"></a>
      <span class="dot">&#183;</span>
			<a href="https://help.marriott.com/s/">Help</a>
    </lobster-layout>
  </div>
</template>

<script>
import * as R from "ramda";

export default {
  name: "footer",
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">
@import "../../node_modules/@hlk/lobster/lobster-global-theme/_variables.scss";
@import "../../node_modules/@hlk/lobster/lobster-global-theme/_mixins.scss";

.footer, .footer a {
  font-family: "Proxima-Nova-Regular", sans-serif;;
  font-size: 10px;
  color: #1c1c1c;
  text-decoration: none;
}

.footer {
  margin-top: 36px;
  margin-bottom: 36px;
  &> lobster-layout {
    border-top: 1px solid #d2d2d2;
    padding-top: 19px;
  }
}


.dot {
  font-size: 20px;
  line-height: 12px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
