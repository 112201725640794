<template>
  <a class="card-link" :href="info.url">
    <div class="card">
      <img :src="info.image" :alt="info.alt"/>
      <div>{{ info.title }}</div>
    </div>
  </a>
</template>

<script>
export default {
  name: "image-card",
  data() {
    return {};
  },
  props: {
    info: {},
  },
  methods: {},
};
</script>

<style lang="scss">
.card {
  flex: 1;
  width: 175px;
  height: 156px;
  border-radius: 12px;
  overflow: hidden;
  text-decoration: none;
  color: white;
  position: relative;
  margin: 10px;
  background-color: black;
  transform: scaleX(1) ScaleY(1);
  transition: transform 0.25s ease-in-out;
}

.card:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  border-radius: 12px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 60%,    
    rgba(0, 0, 0, 0.8) 80%
  ); /* W3C */
}

.card:hover {
  transform-origin: center;
  transition: transform 0.25s linear;
}

.card:hover img {
  transform: scaleX(1.1) ScaleY(1.1);
  transform-origin: center;
  transition: transform 0.25s linear;
}

.card img {
  display: block;
  width: 100%;
  height: auto;
  transform: scaleX(1) ScaleY(1);
  transition: transform 0.25s linear;
}

.card div {
  font-family: Swiss721BT-Regular;
  font-size: 18px;
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: 100%;
  text-align: left;
  z-index: 99;
}

@media screen and (max-width: 769px) {
  .card-link {
    width: 100%;
  }
  .card {
    height: 165px;
    width: 100%;
    max-width: 200px;
    margin: 10px auto;
  }

  .card div{
    font-size: 14px;
  }

}
</style>
