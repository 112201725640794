<template>
  <div class="travel-stories">
    <section-header :title="title"></section-header>
    <lobster-layout
      data-container="centered"
      data-direction="column"
      direction-md="row"
      direction-lg="row"
    >
      <lobster-layout width="100" width-md="50">
        <div
          class="travel-story-img-wrapper aspect_ratio--1-1"
          :style="{ backgroundImage: 'url(' + info.image + ')' }"
        >
          <img class="screen_reader" :src="info.image" :alt="info.alt" />
        </div>
      </lobster-layout>

      <lobster-layout width="100" width-md="50">
        <div class="information">
          <div class="eyebrow">{{ info.eyebrow }}</div>
          <div class="title headline-2">{{ info.title }}</div>
          <div class="tags">
            <template v-for="(tag, index) in info.tags">
              <div class="tag" :key="index">
                {{ tag }}
              </div>
            </template>
          </div>
          <div class="description body-large">
            {{ info.description }}
          </div>
          <div class="lobster_layout-fill">
            <lobster-link
              data-variation="button"
              data="View Story"
              :data-href="info.url"
            ></lobster-link>
          </div>
          <lobster-layout
            data-justify="center"
            data-align="center"
            justify-md="flex-start"
          >
            <a
              class="carousel_button--previous"
              tabindex="0"
              v-on:click="actionClick(-1)"
            >
              <span>&#8592;</span>Previous</a
            >
            <template v-for="(data, index) in jsonData">
              <button
                tabindex="0"
                :key="index"
                v-on:click="dotClick(index)"
                :class="{ selected: index == i }"
                class="pagination-button"
                :title="index"
              ></button>
            </template>
            <a
              class="carousel_button--next"
              tabindex="0"
              v-on:click="actionClick(1)"
              >Next
              <span>&#8594;</span>
            </a>
          </lobster-layout>
        </div>
      </lobster-layout>
    </lobster-layout>
  </div>
</template>

<script>
import travelStories from "@/data/travelStories";
import SectionHeader from "../partials/section-header.vue";

export default {
  name: "private-homes",
  data() {
    return { title: "Travel Stories", jsonData: [], info: {}, i: 0 };
  },
  props: {},
  mounted() {
    this.jsonData = travelStories;
    this.info = this.jsonData[0];
    this.i = 0;
  },
  methods: {
    actionClick(mod) {
      this.i += mod;
      if (this.i < 0) this.i = 0;
      else if (this.i > this.jsonData.length - 1)
        this.i = this.jsonData.length - 1;

      this.info = this.jsonData[this.i];
    },
    dotClick(index) {
      this.i = index;
      this.info = this.jsonData[index];
    },
  },
  components: {
    SectionHeader,
  },
};
</script>

<style lang="scss">
.travel-story-img-wrapper {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 25px;
  overflow: hidden;
}

.travel-stories {
  padding: 80px 0;
  margin-bottom: 30px;

  a {
    text-decoration: none;
  }

  .heading {
    text-align: center;
    width: 100%;
  }

  .lobster_layout-fill {
    display: flex;
    justify-content: center;
    padding: 25px 0;
    @media (min-width: 960px) {
      justify-content: flex-start;
      align-items: center;
    }
  }

  .eyebrow {
    color: #707070;
  }

  .information {
    padding: 25px;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    @media (min-width: 960px) {
      padding: 50px 25px;
      text-align: left;
    }
  }

  .title {
    margin: 5px 0 15px;
  }

  .tags {
    width: 100%;
    border-top: 1px #eee solid;
    border-bottom: 1px #eee solid;
    padding: 6px 0;
  }

  .tag {
    color: #707070;
  }

  .description {
    margin: 15px 0 20px;
  }

  .nav-row a {
    text-decoration: none;
    color: black;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @media screen and (max-width: 959px) {
    .tags {
      border-top: none;
      border-bottom: 1px #000 solid;
    }

    .tag {
      text-align: center;
    }

    .view-story {
      margin: 0 auto;
    }

    .nav-row {
      text-align: center;
    }
  }
}
</style>